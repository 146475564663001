import React, { FC, Fragment, useMemo, useState } from 'react'
import SALink from 'components/SALink'
import Caret from 'svg/caret-right.svg'
import { NavigationLinks } from './data'
import { abTestInSubNavLinks } from './helpers'
import SubNavLinks from './SubNavLinks'
import { debounce, transformNavLinks } from './helpers'

type Props = {
  mobileMenu: string
  updateMobileMenu: (...args: Array<any>) => any
  abTest: Record<string, any>
}

const NavLinks: FC<Props> = ({ mobileMenu, updateMobileMenu, abTest }) => {
  const [subMenu, handleSubMenu] = useState('')

  const transformedNavLinks = useMemo(() => {
    return transformNavLinks({
      navigationLinks: NavigationLinks,
      transformFunction: (navigationLinksParam) =>
        abTestInSubNavLinks({ navigationLinks: navigationLinksParam, abTest }),
    })
  }, [abTest])

  return (
    <nav
      data-section='nav-links'
      onMouseLeave={() => handleSubMenu('')}
      {...(mobileMenu
        ? {
            'data-mobile-visible': true,
          }
        : {})}
    >
      {/* TOP Level Links */}
      {transformedNavLinks.map((navItem: Record<string, any>, index: number) => {
        const { image, links, title, url } = navItem
        // Do not show the 'View All' link for these titles
        const blacklistMenuItems = ['Features', 'Art Advisory'] // These are unique page titles

        // Any matches in the array will have an index of 0 or greater
        // and generate `false` for the boolean.
        const shouldShowViewAll = blacklistMenuItems.findIndex((item) => item === title) === -1
        return (
          <Fragment key={`nav-linkls-${index}`}>
            <div
              data-section='callout'
              {...(mobileMenu === title
                ? {
                    'data-visible': true,
                  }
                : {})}
            >
              <button
                type='button'
                onClick={(ev) => {
                  ev.preventDefault()
                  handleSubMenu('')
                  updateMobileMenu('')
                }}
              >
                <Caret />
              </button>

              <div data-type='title-holder'>
                <SALink
                  data-gtm-event='nav header'
                  data-type='main-link'
                  href={url}
                  onFocus={() =>
                    debounce(() => {
                      handleSubMenu(title)
                    }, 200)
                  }
                  onMouseOver={() =>
                    debounce(() => {
                      handleSubMenu(title)
                    }, 200)
                  }
                  onMouseOut={() => {
                    // using the 'abort' param to stop the debounce
                    // if it hasn't completed.
                    debounce(() => {}, 0, true)
                  }} // onMouseOut needs an onBlur call for accessibility,
                  onBlur={() => {
                    debounce(() => {}, 0, true)
                  }}
                >
                  {title}
                </SALink>

                {shouldShowViewAll && (
                  <SALink
                    data-gtm-event='nav header'
                    data-type='see-all'
                    href={url}
                    rel='noopener noreferrer'
                  >
                    View All
                  </SALink>
                )}
              </div>
            </div>
            {/* SUB Level Links */}
            <div
              data-section='submenu'
              {...(subMenu
                ? {
                    'data-visible': true,
                  }
                : {})}
            >
              <SubNavLinks
                image={image}
                links={links}
                isVisible={subMenu === title || mobileMenu === title}
              />
            </div>
          </Fragment>
        )
      })}
    </nav>
  )
}

export default NavLinks
