import React, { useState } from 'react'
import CaretIcon from 'svg/caret-down.svg'
import { capitalizeString } from 'lib/helpers'
import { DesktopDropdown, DesktopDropdownLink } from './styles'
type Props = {
  onChange: (...args: Array<any>) => any
  options: Array<string>
  searchType?: string
}

const Dropdown = ({
  onChange,
  options,
  searchType,
}: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const [isOpen, toggleDropdown] = useState(false)
  return (
    <>
      <button
        data-type='dropdown-caret'
        aria-label='Select Search Item'
        id='toggleDropdown'
        onClick={() => toggleDropdown(!isOpen)}
        tabIndex={0}
        type='button'
      >
        <CaretIcon />
      </button>

      <DesktopDropdown
        tabIndex='0'
        role='listbox'
        aria-labelledby='toggleDropdown'
        data-type='search-dropdown'
        data-visible={isOpen}
      >
        {options.map(
          (type: string): React.ReactElement<React.ComponentProps<any>, any> => (
            <DesktopDropdownLink
              data-selected={type === searchType}
              aria-label='Submit'
              key={type}
              onClick={() => onChange(type)}
              role='option'
              tabIndex={0}
            >
              {capitalizeString(type)}
            </DesktopDropdownLink>
          )
        )}
      </DesktopDropdown>
    </>
  )
}

export default Dropdown