import React from 'react'
import type { ConnectorProps } from 'connectors/UserPreferences'
import UserPreferences from 'connectors/UserPreferences'
import type { ConnectorProps as GeoLocationProps } from 'connectors/GeoLocation'
import GeoLocationConnector from 'connectors/GeoLocation'
import { createNewBrowserPath } from 'components/GeoLocation/helpers'
import { setClientStorage } from 'store/middleware/client'
import { fetchAllClientData } from 'apis/client'
import Modal from './Base'
import Preferences from '../Forms/Preferences'
type Props = ConnectorProps &
  GeoLocationProps & {
    closeModal: (...args: Array<any>) => any
  }

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    availableCountries,
    closeModal,
    currenciesDropdownData,
    isAuthenticated,
    updateUserPreferences,
    userPreferences,
  } = props

  const handleSubmit = async () => {
    // Fetch Session and Cart data
    const { payload: allClientData, success: wasAllClientDataFetched } = await fetchAllClientData()

    if (!wasAllClientDataFetched) {
      console.error('Problem Fetching Session')
      return
    }

    const { country: userPreferencesCountry } = userPreferences
    setClientStorage(allClientData)
    updateUserPreferences(allClientData) // update user preferences in the DB from session

    const { country: newCountry } = allClientData.cookie.userPreferences
    const shouldRedirect = userPreferencesCountry !== newCountry

    if (shouldRedirect) {
      const newPrefix = `${`en-${newCountry.toLowerCase()}`}`
      const path = createNewBrowserPath(newPrefix)
      window.location = path
    }

    closeModal()
  }

  return (
    <Modal closeModal={closeModal}>
      <Preferences
        availableCountries={availableCountries}
        currenciesDropdownData={currenciesDropdownData}
        isAuthenticated={isAuthenticated}
        onSubmit={handleSubmit}
        userPreferences={userPreferences}
      />
    </Modal>
  )
}

export default GeoLocationConnector(UserPreferences(Component))