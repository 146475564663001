import React, { useEffect, useState } from 'react'
import type { ConnectorProps as SearchProps } from 'connectors/navigation/Search'
import SearchConnector from 'connectors/navigation/Search'
import MagGlassIcon from 'svg/mag-glass.svg'
import CloseIcon from 'svg/close-x.svg'
import { capitalizeString } from 'lib/helpers'
import CaretIcon from 'svg/caret-down.svg'
import { checkDelayedSearch, submitSearch, delayedSearch } from './helpers'
import Dropdown from './Dropdown'
import { SearchNavigation } from './styles'
const OPTIONS = ['art', 'artist']
const DEFAULT_OPTION = OPTIONS[0]
type Props = SearchProps & GeoLocationProps

const Search = (props: Props): React$ElementRef<any> => {
  const { searchQuery, searchType: preSelectedSearchType } = props
  const [searchValue, updateSearchValue] = useState(searchQuery || '')
  const [searchType, updateSearchType] = useState(preSelectedSearchType || DEFAULT_OPTION)
  const [isOpen, toggleOpen] = useState(false)
  // We need to UPDATE Query when USER uses native HISTORY ( Back | Next ) in BROWSER
  useEffect(() => {
    if (searchValue !== searchQuery) {
      updateSearchValue(searchQuery)
    }
  }, [searchQuery])
  return (
    <>
      <button
        aria-label='button'
        data-type='toggle-search'
        type='button'
        onClick={(ev) => {
          ev.preventDefault()
          toggleOpen(true)
        }}
      >
        <MagGlassIcon />
      </button>

      <SearchNavigation
        data-section='search'
        action='#'
        onSubmit={(ev) => {
          ev.preventDefault()
          submitSearch({
            query: searchValue,
            type: searchType,
          })
        }}
        {...(isOpen
          ? {
              'data-visible': true,
            }
          : {})}
      >
        <MagGlassIcon
          data-type='search-icon'
          onClick={(ev) => {
            ev.preventDefault()
            submitSearch({
              query: searchValue,
              type: searchType,
            })
          }}
        />
        <input
          aria-label='Search'
          autoComplete='off'
          name='query'
          onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => {
            const { value: query } = ev.currentTarget
            updateSearchValue(query || '')

            // If page is a delaySearch, start timer to update
            if (checkDelayedSearch()) {
              delayedSearch(() =>
                submitSearch({
                  query,
                  type: searchType,
                })
              )
            }
          }}
          placeholder={capitalizeString(searchType)}
          type='search'
          value={searchValue}
        />

        <div data-type='search-mobile'>
          <p>{capitalizeString(searchType)}</p>
          <CaretIcon />

          <select
            data-type='select-mobile'
            onChange={(ev: React.SyntheticEvent<HTMLSelectElement>) => {
              const { value: type } = ev.currentTarget
              updateSearchType(type)
            }}
            value={searchType}
          >
            {OPTIONS.map((type: string) => (
              <option value={type} key={type}>
                {capitalizeString(type)}
              </option>
            ))}
          </select>
        </div>

        <button
          aria-label='Close'
          data-type='close-search'
          type='button'
          onClick={(ev) => {
            ev.preventDefault()
            toggleOpen(false)
          }}
        >
          <CloseIcon />
        </button>

        <Dropdown
          onChange={(type) => updateSearchType(type)}
          options={OPTIONS}
          searchType={searchType}
        />
      </SearchNavigation>
    </>
  )
}

export default SearchConnector(Search)