import React, { useEffect, useState } from 'react'
import type { ConnectorProps as AuthenticationProps } from 'connectors/Authentication'
import AuthenticationConnect from 'connectors/Authentication'
import SALink from 'components/SALink'
import AccountIcon from 'svg/account.svg'
import { DropdownMenu } from './styles'
type Props = AuthenticationProps

const AuthLinks = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { showModal } = props
  const [location, updateLocation] = useState('/')
  const [dropdownIsOpen, handleDropdownOpen] = useState(false)
  useEffect(() => {
    const { href } = window.location
    updateLocation(href)
  })
  return (
    <>
      <button
        type='button'
        data-type='account-icon'
        aria-label='authentication links'
        onClick={() => {
          handleDropdownOpen(!dropdownIsOpen)
        }}
      >
        <AccountIcon />
      </button>

      <DropdownMenu
        data-type='dropdown'
        data-layout='inline'
        {...(dropdownIsOpen
          ? {
              'data-visible': true,
            }
          : {})}
      >
        <SALink
          data-gtm-event='nav header'
          data-type='account-links'
          href={`/authentication/?redirect_url=${encodeURIComponent(location)}`}
          onClick={(ev: React.SyntheticEvent<Record<string, any>>) => {
            ev.preventDefault()
            showModal('login')
          }}
          rel='nofollow'
        >
          Log In
        </SALink>

        <SALink
          data-gtm-event='nav header'
          data-type='account-links'
          href={`/authentication/register/?redirect_url=${encodeURIComponent(location)}`}
          onClick={(ev: React.SyntheticEvent<Record<string, any>>) => {
            ev.preventDefault()
            showModal('register')
          }}
          rel='nofollow'
        >
          Register
        </SALink>
      </DropdownMenu>
    </>
  )
}

export default AuthenticationConnect(AuthLinks)