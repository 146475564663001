import fetch from 'isomorphic-fetch'
import { getOptions, postOptions, parseJSON } from 'apis/helpers'
import { deleteCartStorage } from 'components/CartUrgencyBar/helper'
import { resetClientStorage } from '../../store/middleware/client'

/* Authentication */
export const postLogin = async (payload: Record<string, any>): Promise<Record<string, any>> => {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      remember: true,
      ...payload,
    }),
  }
  const response = await fetch(`/auth-api/login`, options)
  const json = await parseJSON(response, 'fetchLogin')
  const {
    success,
    user_id: userId,
    user_email: userEmail,
    user_type: userType,
    ip,
    messages,
  } = json
  return {
    ip: ip || '',
    success: success || false,
    userEmail: userEmail || '',
    userId: String(userId || ''),
    userType: (userType || '').toLowerCase(),
    messages: messages || [],
  }
}
export const fetchCheckFacebookConnect = async (
  payload: Record<string, any>
): Promise<Record<string, any>> => {
  const options = getOptions
  const response = await fetch(`/auth-api/check-facebook-connect`, options)
  const json = await parseJSON(response, 'fetchCheckFacebookConnect')
  return json
}
export const postRegister = async (payload: Record<string, any>): Promise<Record<string, any>> => {
  const options = { ...postOptions, body: JSON.stringify({ ...payload }) }
  const response = await fetch(`/easel_api/auth/register`, options)
  const json = await parseJSON(response, 'postRegister')
  return json
}
export const postResetPassword = async (
  payload: Record<string, any>
): Promise<Record<string, any>> => {
  const options = { ...postOptions, body: JSON.stringify({ ...payload }) }
  const response = await fetch(`/easel_api/auth/reset-password`, options)
  const json = await parseJSON(response, 'postResetPassword')
  return json
}
export const postNewPassword = async (
  payload: Record<string, any>
): Promise<Record<string, any>> => {
  const options = { ...postOptions, body: JSON.stringify({ ...payload }) }
  const response = await fetch(`/easel_api/auth/new-password`, options)
  const json = await parseJSON(response, 'postNewPassword')
  return json
}

export const getLogout = async (payload: Record<string, any>): Promise<Record<string, any>> => {
  // Session Data - user data
  resetClientStorage()
  // Set local storage to check cart urgency flash message
  deleteCartStorage()
  const options = getOptions
  const response = await fetch(`/easel_api/auth/logout`, options)
  const json = await parseJSON(response, 'getLogout')
  return json
}
