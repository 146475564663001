import type { SiteContext } from 'types/SiteContext'
import 'types/SiteContext'
import type { Artist } from 'types/UserType'
import 'types/UserType'
import { NavigationLinksArray } from './NavigationLinks.types'
const userTypeArtist: Artist = 2
type LogoInfo = {
  logo: string
  width: string
  height?: string
}
type NavigationInfo = {
  siteContext: SiteContext
  url: string
  desktop: LogoInfo
  mobile: LogoInfo
  alt: string
  isNew: boolean
}
type ContextData = Record<SiteContext, NavigationInfo>
type AuthorizedUserParams = {
  profileUrl: string
  userId: number
  userTypeId: number
}
type AccountLinks = Array<{
  title: string
  url: string
}>
const siteUrl = process.env.SITE_URL || 'https://www.saatchiart.com'
export const siteContextData: ContextData = {
  saatchiart: {
    siteContext: 'saatchiart',
    url: process.env.SITE_URL || '',
    desktop: {
      logo: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/sa-logo.svg',
      width: '200px',
      height: '29.844px',
    },
    mobile: {
      logo: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/sa-logo.svg',
      width: '154px',
      height: '23px',
    },
    alt: 'Saatchi Art logo',
    isNew: false,
  },
  limited: {
    siteContext: 'limited',
    url: `${siteUrl}/prints?prints_availability=limited` || '',
    desktop: {
      logo: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/limited-logo-grayscale.svg',
      width: '119.25px',
      height: '45px',
    },
    mobile: {
      logo: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/limited-logo-grayscale.svg',
      width: '66.25px',
    },
    alt: 'Limited Edition Art Prints',
    isNew: false,
  },
  toaf: {
    siteContext: 'toaf',
    url: process.env.TOAF_SITE_URL || 'http://www.local.saatchiart.com/theotherartfair',
    desktop: {
      logo: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/toaf-logo-color.svg',
      width: '200px',
      height: '23.891px',
    },
    mobile: {
      logo: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/toaf-icon.svg',
      width: '167.5px',
    },
    alt: 'The Other Art Fair',
    isNew: false,
  },
}
export const getSiteContextArray: Array<NavigationInfo> = [
  siteContextData.saatchiart,
  siteContextData.limited,
  siteContextData.toaf,
]
export const getSiteContextData = (siteContext: SiteContext): NavigationInfo =>
  siteContextData[siteContext]
export const getAccountLinks = (location: string): AccountLinks => [
  {
    title: 'Log In',
    url: `/authentication/?redirect_url=${encodeURIComponent(location)}`,
  },
  {
    title: 'Register',
    url: `/authentication/register/?redirect_url=${encodeURIComponent(location)}`,
  },
]
export const getAuthorizedAccountLinks = (params: AuthorizedUserParams): AccountLinks => {
  const { profileUrl, userId, userTypeId } = params
  const links = [
    {
      title: 'Profile',
      url: profileUrl,
    },
    {
      title: 'Account Information',
      url: '/accounts/settings',
    },
    {
      title: 'Favorites',
      url: `/account/favorite/${userId}`,
    },
    {
      title: 'Collections',
      url: `/account/collection/${userId}`,
    },
    {
      title: 'Studio',
      url: `/studio`,
    },
    {
      title: 'Orders',
      url: '/accounts/orders',
    },
    {
      title: 'Offers Dashboard',
      url: '/accounts/offers',
    },
  ]

  if (userTypeId === userTypeArtist) {
    links.push({
      title: 'Artworks',
      url: `/account/artworks/${userId}`,
    })
  }

  links.push({
    title: 'Log Out',
    url: '/sign-out',
  })
  return links
}

export const NavigationLinks: NavigationLinksArray = [
  {
    title: 'Paintings',
    url: '/paintings',
    image: {
      url: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/saatchi-painting.jpg',
      title: 'Featured Paintings',
      linkTo: 'https://www.saatchiart.com/art-collection/Featured-Paintings/1754780/657827/view',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Fine Art',
            url: '/paintings/fine-art',
          },
          {
            title: 'Abstract',
            url: '/paintings/abstract',
          },
          {
            title: 'Modern',
            url: '/paintings/modern',
          },
          {
            title: 'Street Art',
            url: '/paintings/street-art',
          },
          {
            title: 'Pop Art',
            url: '/paintings/pop-art',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Portrait',
            url: '/paintings/portrait',
          },
          {
            title: 'Landscape',
            url: '/paintings/landscape',
          },
          {
            title: 'Still Life',
            url: '/paintings/still-life',
          },
          {
            title: 'Nature',
            url: '/paintings/nature',
          },
          {
            title: 'Beach',
            url: '/paintings/beach',
          },
        ],
      },
      {
        title: 'Medium',
        links: [
          {
            title: 'Oil',
            url: '/paintings/oil',
          },
          {
            title: 'Watercolor',
            url: '/paintings/watercolor',
          },
          {
            title: 'Acrylic',
            url: '/paintings/acrylic',
          },
          {
            title: 'Airbrush',
            url: '/paintings/airbrush',
          },
          {
            title: 'Ink',
            url: '/paintings/ink',
          },
        ],
      },
    ],
  },
  {
    title: 'Photography',
    url: '/photography',
    image: {
      url: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/saatchi-photography.jpg',
      title: 'Featured Photography',
      linkTo: 'https://www.saatchiart.com/art-collection/Featured-Photography/1586325/615783/view',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Fine Art',
            url: '/photography/fine-art',
          },
          {
            title: 'Portraiture',
            url: '/photography/portraiture',
          },
          {
            title: 'Abstract',
            url: '/photography/abstract',
          },
          {
            title: 'Documentary',
            url: '/photography/documentary',
          },
          {
            title: 'Conceptual',
            url: '/photography/conceptual',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Landscape',
            url: '/photography/landscape',
          },
          {
            title: 'Portrait',
            url: '/photography/portrait',
          },
          {
            title: 'Nature',
            url: '/photography/nature',
          },
          {
            title: 'Still Life',
            url: '/photography/still-life',
          },
          {
            title: 'Nude',
            url: '/photography/nude',
          },
        ],
      },
      {
        title: 'Medium',
        links: [
          {
            title: 'Digital',
            url: '/photography/digital',
          },
          {
            title: 'Black and White',
            url: '/photography/black-white',
          },
          {
            title: 'Color',
            url: '/photography/color',
          },
          {
            title: 'C-type',
            url: '/photography/c-type',
          },
          {
            title: 'Digital',
            url: '/photography/gelatin',
          },
        ],
      },
    ],
  },
  {
    title: 'Sculpture',
    url: '/sculpture',
    image: {
      url: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/saatchi-sculpture.jpg',
      title: 'Featured Sculptures',
      linkTo:
        'https://www.saatchiart.com/art-collection/sculpture-painting/Featured-Sculptures/1861756/726057/view',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Pop Art',
            url: '/sculpture/pop-art',
          },
          {
            title: 'Abstract',
            url: '/sculpture/abstract',
          },
          {
            title: 'Wall',
            url: '/sculpture/wall',
          },
          {
            title: 'Figurative',
            url: '/sculpture/figurative',
          },
          {
            title: 'Modern',
            url: '/sculpture/modern',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Body',
            url: '/sculpture/body',
          },
          {
            title: 'Still Life',
            url: '/sculpture/still-life',
          },
          {
            title: 'Animal',
            url: '/sculpture/animal',
          },
          {
            title: 'Architecture',
            url: '/sculpture/architecture',
          },
          {
            title: 'Geometric',
            url: '/sculpture/geometric',
          },
        ],
      },
      {
        title: 'Medium',
        links: [
          {
            title: 'Metal',
            url: '/sculpture/metal',
          },
          {
            title: 'Bronze',
            url: '/sculpture/bronze',
          },
          {
            title: 'Clay',
            url: '/sculpture/clay',
          },
          {
            title: 'Glass',
            url: '/sculpture/glass',
          },
          {
            title: 'Wood',
            url: '/sculpture/wood',
          },
        ],
      },
    ],
  },
  {
    title: 'Drawings',
    url: '/drawings',
    image: {
      url: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/saatchi-drawing-v4.jpg',
      title: 'Featured Drawings',
      linkTo: 'https://www.saatchiart.com/art-collection/New-Featured-Drawings/1012074/615613/view',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Graffiti',
            url: '/drawings/graffiti',
          },
          {
            title: 'Abstract',
            url: '/drawings/abstract',
          },
          {
            title: 'Fine Art',
            url: '/drawings/fine-art',
          },
          {
            title: 'Pop Art',
            url: '/drawings/pop-art',
          },
          {
            title: 'Surrealism Art',
            url: '/drawings/surrealism',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Nature',
            url: '/drawings/nature',
          },
          {
            title: 'Portrait',
            url: '/drawings/portrait',
          },
          {
            title: 'Animal',
            url: '/drawings/animal',
          },
          {
            title: 'Love',
            url: '/drawings/love',
          },
          {
            title: 'Cartoon',
            url: '/drawings/cartoon',
          },
        ],
      },
      {
        title: 'Medium',
        links: [
          {
            title: 'Pencil',
            url: '/drawings/pencil',
          },
          {
            title: 'Charcoal',
            url: '/drawings/charcoal',
          },
          {
            title: 'Digital',
            url: '/drawings/digital',
          },
          {
            title: 'Ink',
            url: '/drawings/ink',
          },
          {
            title: 'Pastel',
            url: '/drawings/pastel',
          },
        ],
      },
    ],
  },
  {
    title: 'Prints',
    url: '/prints',
    image: {
      url: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/saatchi-featured-prints.jpg',
      title: 'Fine Art Prints',
      linkTo: 'https://www.saatchiart.com/stories/fine-art-prints',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Fine Art',
            url: '/prints/fine-art',
          },
          {
            title: 'Abstract',
            url: '/prints/abstract',
          },
          {
            title: 'Art Deco',
            url: '/prints/art-deco',
          },
          {
            title: 'Pop Art',
            url: '/prints/pop-art',
          },
          {
            title: 'Folk Art',
            url: '/prints/folk',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Floral',
            url: '/prints/floral',
          },
          {
            title: 'Animal',
            url: '/prints/animal',
          },
          {
            title: 'Fashion',
            url: '/prints/fashion',
          },
          {
            title: 'Beach',
            url: '/prints/beach',
          },
          {
            title: 'Geometric',
            url: '/prints/geometric',
          },
        ],
      },
      {
        title: 'Material',
        links: [
          {
            title: 'Fine Art Paper',
            url: '/prints?prints_materials=rag',
          },
          {
            title: 'Photo Paper',
            url: '/prints?prints_materials=photo',
          },
          {
            title: 'Canvas',
            url: '/prints?prints_materials=canvas',
          },
          {
            title: 'Metal',
            url: '/prints?prints_materials=metal',
          },
          {
            title: 'Acrylic',
            url: '/prints?prints_materials=acrylic',
          },
        ],
      },
    ],
  },
  {
    title: 'Inspiration',
    url: '/stories',
    image: {
      title: 'Featured Collections',
      url:
        'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/saatchi-featured-collection-2.jpg',
      linkTo: '/collections/featured',
    },
    links: [
      {
        links: [
          {
            title: 'Curated Collections',
            url: '/collections/featured',
          },
          {
            title: 'Digital Exhibitions',
            url: '/shows',
          },
          {
            title: 'Catalog',
            url: '/catalog',
          },
          {
            title: 'Art Stories',
            url: '/stories',
          },
          {
            title: 'Trending Artists',
            url: '/trendingartists',
          },
          {
            title: 'Living With Art',
            url: '/livingwithart',
          },
        ],
      },
      {
        links: [
          {
            title: 'Rising Stars',
            url: '/risingstars',
          },
          {
            title: 'The Other Art Fair',
            url: '/theotherartfair',
          },
          {
            title: 'Art Match',
            url: '/artmatch',
          },
          {
            title: 'Art Style Quiz',
            url: '/quiz/art-for-your-style-quiz ',
          },
          {
            title: 'Blog',
            url: '//canvas.saatchiart.com/',
          },
        ],
      },
    ],
  },
  {
    title: 'Art Advisory',
    url: '/artadvisory',
    image: {
      title: 'Find Art You Love',
      url: 'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/aa-panel-desk.jpg',
      linkTo: '/artadvisory',
    },
    links: [
      {
        links: [
          { title: 'About', url: '/artadvisory' },
          { title: 'Meet The Experts', url: '/artadvisory#team' },
          { title: 'Contact Art Advisors', url: '/artadvisory/brief' },
        ],
      },
    ],
  },
  {
    title: 'Trade',
    url: '/trade',
    image: {
      title: 'Saatchi Art for Business',
      url: 'https://d3t95n9c6zzriw.cloudfront.net/trade/home/tradeprogram.jpg',
      linkTo: '/trade',
    },
    links: [
      {
        links: [
          {
            title: 'About',
            url: '/trade',
          },
          {
            title: 'Trade Program',
            url: '/trade/trade-program',
          },
          {
            title: 'Hospitality',
            url: '/trade/hospitality',
          },
          {
            title: 'Commercial',
            url: '/trade/commercial',
          },
          {
            title: 'Health Care',
            url: '/trade/healthcare',
          },
          {
            title: 'Multi Family Residential',
            url: '/trade/residential',
          },
          {
            title: 'Contact Art Consultant',
            url: '/trade-hospitality#contact',
          },
        ],
      },
    ],
  },
]