import React from 'react'
import type { ConnectorProps as SessionProps } from 'containers/Session'
import SessionConnect from 'containers/Session'
import AuthLinks from './AuthLinks'
import UserInfo from './UserInfo'
import { AuthenticationWrapper } from './styles'
type Props = SessionProps
const Authentication = SessionConnect((props: Props): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { isSessionReady, authenticated } = props

  if (!isSessionReady) {
    return null
  }

  return (
    <AuthenticationWrapper data-section='authentication'>
      {authenticated ? <UserInfo /> : <AuthLinks />}
    </AuthenticationWrapper>
  )
})
export default Authentication