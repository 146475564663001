import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import ContextMenu, { ContextMenuActive } from 'components/ContextMenu'
import { PlainLink } from 'components/A'
import MagGlass from 'svg/mag-glass.svg'
import CloseX from 'svg/close-x.svg'
export const MagGlassIcon = styled(MagGlass)`
  height: 15px;
  width: 16.4219px;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    rect {
      fill: ${(props) => props.theme.colors.blue};
    }
    ellipse {
      stroke: ${(props) => props.theme.colors.blue};
    }
  }
`
export const CloseIcon = styled(CloseX)`
  height: 23px;
  width: 23px;
  padding: 5px;
  background: ${(props) => props.theme.colors.backgroundGray};
  border-radius: 50%;
  path {
    stroke: ${(props) => props.theme.colors.default};
  }
`
export const Submit = styled.button`
  width: 70px;
  ${MagGlassIcon};
`
export const Close = styled.button`
  width: 50px;
  ${CloseIcon};
`
export const CaretContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: ${(props) => props.theme.space[2]}px 13px;
  outline: 0;
  border-right: 1px solid #cccccc;
`
export const Input = styled.input`
  border: none;
  padding: 10px 30px 5px 10px;
  width: 100%;
  &:focus {
    outline: 0;
  }
  &::placeholder {
    color: #666666;
  }
  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-right: 30px;
    border-bottom: 1px solid ${(props) => props.theme.colors.backgroundGray};
  }
`
export const DesktopDropdown = styled(ContextMenu)`
  position: absolute;
  top: 103%;
  width: 100%;
  z-index: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  &[data-visible='true'] {
    ${ContextMenuActive};
  }
`
export const DesktopDropdownLink = styled(PlainLink)``
export const SearchNavigation = styled.form`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 280px;
  height: 40px;
  border: 1px solid #cccccc;

  input[name='query'] {
    border: none;
    padding: 10px 5px 5px 10px;
    width: 100%;
    ::placeholder {
      color: #999999;
    }
    :focus {
      outline: none;
      ::placeholder {
        color: #333333;
      }
    }
  }

  [data-type='dropdown-caret'] {
    padding: 10px 13px;
    border-right: 1px solid #cccccc;
    height: 100%;
    svg {
      height: 8px;
      width: 14px;
      g {
        stroke: ${(props) => props.theme.colors.secondary};
      }
    }
  }

  [data-type='search-dropdown'] {
    a {
      font-size: 16px;
      font-weight: normal;
      padding: 0;
      outline: 0;
      &[data-selected='true'] {
        font-weight: bold;
      }
      :hover {
        color: ${(props) => props.theme.colors.black};
      }
    }
  }

  svg[data-type='search-icon'] {
    margin-right: 10px;
    height: 30px;
    width: 30px;
    g {
      stroke: ${(props) => props.theme.colors.secondary};
    }
  }

  [data-type='toggle-search'] {
    padding: 10px 13px;
    svg {
      height: 35px;
      width: 14px;
      g {
        stroke: ${(props) => props.theme.colors.secondary};
      }
    }
  }

  [data-type='close-search'] {
    display: none;
  }
  @media (max-width: 1250px) {
    width: 170px;
  }
  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 15px;
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    right: 0;
    z-index: 25;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease opacity 0.35s;
    contain: strict;
    will-change: opacity;
    border: none;
    ${CaretContainer} {
      display: none;
    }

    &[data-visible] {
      opacity: 1;
      pointer-events: initial;
    }

    [data-type='close-search'] {
      display: flex;
      width: 30px;
      height: 25px;
      padding: 5px;
      background: #f3f3f3;
      border-radius: 50%;
      svg {
        width: 10px;
        height: 10px;
      }
    }
    svg[data-type='search-icon'] {
      height: 23px;
      width: 23px;
      rect {
        stroke: #39c;
        fill: #39c;
      }
      ellipse {
        stroke: #39c;
      }
    }
    input[name='query'] {
      border-bottom: 1px solid #f3f3f3;
      margin: 0 10px;
    }
    [data-type='dropdown-caret'] {
      display: none;
    }
  }
  button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }

  [data-type='search-mobile'] {
    display: none;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      width: 90px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      position: relative;
      margin: 0 10px 0 0;
      padding: 3px 10px;
      border-radius: 90px;
      background-color: #f3f3f3;
      text-shadow: -1px -1px 1px #fff;
      color: #666;
      svg {
        width: 7px;
        height: 14px;
        margin-left: 10px;
      }
      select {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`