import React, { useState, useEffect } from 'react'
import SALink from 'components/SALink'
import type { ConnectorProps as HeaderProps } from 'connectors/navigation/Header'
import HeaderConnector from 'connectors/navigation/Header'
import GiftCard from 'svg/gift-card.svg'
import Heart from 'svg/heart.svg'
import Cart from 'svg/cart.svg'
import SaatchiLogo from 'svg/sa-logo.svg'
import StudioLink from './StudioLink'
import Logos from './Logos'
import NavLinks from './NavLinks'
import MobileNavLinks from './MobileNavLinks'
import Search from './Search'
import CartCount from './CartCount'
import Authentication from './Authentication'
import { Header } from './styles'
import ABTestConnector, { ConnectorProps as ABTestProps } from 'connectors/ABTest'

/*
DESKTOP
  -> LOGOS            AUTH
  -> BRAND   NAV    SEARCH

MOBILE
  -> HAMBUGER BRAND  ICONS
*/
type Props = ABTestProps & HeaderProps & {
  siteContext: string
}

const Navigation = (props: Props) => {
  const { siteContext, isAuthenticated, userId, abTest } = props
  const [isMobileOpen, updateIsMobileOpen] = useState(false) // Should the Mobile Menu Show

  const [mobileMenu, updateMobileMenu] = useState('') // Submenu that should be visible

  const [offset, setOffset] = useState(0) // where should the mobile menu show (top offset)

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = isMobileOpen ? 'hidden' : 'auto'
  }, [isMobileOpen])
  return (
    <Header data-section='header' offset={offset}>
      <div data-section='top'>
        <Logos siteContext={siteContext} />

        <div data-section='icons'>
          <Authentication />

          <StudioLink />

          <SALink
            data-type='giftcard'
            data-gtm-event='nav header'
            href='/giftcard'
            title='Saatchi Art eGift Card'
          >
            <GiftCard data-type='gift-card-icon' width='30' height='20' />
          </SALink>

          <SALink
            aria-label='View your favorites'
            data-type='favorites'
            data-gtm-event='nav header'
            href={isAuthenticated ? `/account/favorite/${userId}` : '/account/favorites'}
            rel='nofollow'
          >
            <Heart data-type='heart-icon' width='26' height='23' />
          </SALink>

          <SALink
            aria-label='View your cart'
            data-type='cart'
            data-gtm-event='nav header'
            href='/checkout'
            rel='nofollow'
          >
            <span data-type='cart-count'>
              <CartCount />
            </span>
            <Cart data-type='cart-icon' width='22' height='28' />
          </SALink>
        </div>
      </div>

      <div data-section='bottom'>
        <MobileNavLinks
          isMobileOpen={isMobileOpen}
          updateIsMobileOpen={updateIsMobileOpen}
          updateMobileMenu={updateMobileMenu}
          setOffset={setOffset}
        />

        <>
          {siteContext === 'toaf' ? (
            <SALink
              data-type='saatchi-logo'
              data-gtm-event='nav header'
              href={process.env.SITE_URL || '/theotherartfair'}
              title='The Other Art Fair Home'
            >
              <img
                alt='The Other Art Fair logo'
                src='https://d3t95n9c6zzriw.cloudfront.net/navigation/toaf-logo-color.svg'
                width='200'
                height='24'
              />
            </SALink>
          ) : (
            <SALink
              data-type='saatchi-logo'
              data-gtm-event='nav header'
              href={process.env.SITE_URL || '/'}
              title='Saatchi Art home'
            >
              <SaatchiLogo alt='Saatchi Art logo' width='200' height='30' />
            </SALink>
          )}
        </>

        <NavLinks mobileMenu={mobileMenu} updateMobileMenu={updateMobileMenu} abTest={abTest} />

        <span data-type='spacer' />

        <Search />
      </div>
    </Header>
  )
}

export default ABTestConnector(HeaderConnector(Navigation))