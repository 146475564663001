import React, { useRef } from 'react'
import SALink from 'components/SALink'
import GiftCard from 'svg/gift-card.svg'
import Caret from 'svg/caret-right.svg'
import ToafLogo from 'svg/toaf-logo.svg'
import { NavigationLinks } from './data'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  isMobileOpen: boolean
  updateIsMobileOpen: (...args: Array<any>) => any
  updateMobileMenu: (...args: Array<any>) => any
  setOffset: (...args: Array<any>) => any
}

const MobileNavLinks = ({
  isMobileOpen,
  updateIsMobileOpen,
  updateMobileMenu,
  setOffset,
}: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const mobileMenuRef = useRef({})
  return (
    <>
      <label
        ref={mobileMenuRef}
        htmlFor='toggle-menu'
        data-type='hamburger'
        {...(isMobileOpen
          ? {
              'data-mobile-open': true,
            }
          : {})}
      >
        <input
          id='toggle-menu'
          type='checkbox'
          {...(isMobileOpen
            ? {
                checked: 'true',
              }
            : {})}
          /* Issue with history NOT resetting state */
          {...(isMobileOpen
            ? {
                'data-checked': 'true',
              }
            : {})}
          onChange={(ev) => {
            const { checked } = ev.currentTarget

            // determine the top offset of where the mobile menu should be displayed
            // whenever the burger menu is toggled
            if (mobileMenuRef.current && mobileMenuRef.current.getBoundingClientRect) {
              const { top, height } = mobileMenuRef.current.getBoundingClientRect()
              setOffset(top + height)
            }

            updateIsMobileOpen(checked || false)
            updateMobileMenu('')
          }}
        />
        <span data-type='line' />
        <span data-type='line' />
        <span data-type='line' />
      </label>

      <div
        data-section='mobile-nav'
        {...(isMobileOpen
          ? {
              'data-mobile-open': true,
            }
          : {})}
      >
        <div data-type='mobile-nav-content'>
          {NavigationLinks.map((navItem: Record<string, any>) => {
            const { title, links, url } = navItem

            if (links) {
              return (
                <button
                  type='button'
                  data-gtm-event='nav header'
                  data-type='mobile-link'
                  key={title}
                  onClick={() => {
                    updateMobileMenu(title)
                  }}
                >
                  <SATypography variant={SATypographyVariant.MEDIUM} data-style='paragraph-wp'>
                    {title}
                  </SATypography>
                  <Caret />
                </button>
              )
            } else {
              return (
                <SALink data-gtm-event='nav header' data-type='mobile-link' href={url} key={title}>
                  {title}
                </SALink>
              )
            }
          })}

          <div data-type='divider' />

          <SALink
            data-gtm-event='nav header'
            data-type='mobile-link'
            href={process.env.TOAF_SITE_URL || 'http://www.local.saatchiart.com/theotherartfair'}
            aria-label='The Other Art Fair'
          >
            <ToafLogo alt='Saatchi Art logo' width='144' height='18' />
          </SALink>

          <SALink
            data-gtm-event='nav header'
            data-type='mobile-link'
            href='/giftcard'
            title='Saatchi Art eGift Card'
          >
            <GiftCard data-type='gift-card-icon' width='32' height='23' />
            <span data-type='gift-card'>Saatchi Art eGift Card</span>
          </SALink>
        </div>
      </div>
    </>
  )
}

export default MobileNavLinks
