import React from 'react'
import NProgress from 'nprogress'
import Router from 'next/router'
import AuthenticationModal from 'components/Authentication/Modals'
import CartQuickView from 'components/Cart/QuickView'
import FlashMessage from 'components/FlashMessage'
import WindowListener from 'components/Screen'
import useClientSession from 'hooks/clientSession'
import TakeoverIndicator from './TakeoverIndicator'
import Navigation from './Navigation'
// https://ricostacruz.com/nprogress/ slim JS loading bar
// We are connecting it to page ( clientside ) transitions in NEXTjs
// to give user some indication of an ACTION taking place
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: true,
  trickleSpeed: 300,
})

Router.onRouteChangeStart = (url) => NProgress.start()

Router.onRouteChangeComplete = () => NProgress.done()

Router.onRouteChangeError = () => NProgress.done()

const Header = (props: {
  siteContext: string
}): React.ReactElement<React.ComponentProps<any>, any> => {
  const { siteContext } = props
  useClientSession()

  return (
    <nav data-section='main-navigation'>
      <TakeoverIndicator />
      <Navigation siteContext={siteContext} />
      <AuthenticationModal />
      <FlashMessage />
      <CartQuickView />
      <WindowListener />
    </nav>
  )
}
export default Header
