import React, { useState } from 'react'
import { newsletterSignupApi } from 'apis/newsletter'
import { REQUEST_SOURCE } from '../../../server/constants/newsletter_request_sources'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Status = {
  message: string
  status: 'start' | 'error' | 'status'
}

const EmailSignup = (): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const [apiStatus, updateApisStatus] = useState({
    message: '',
    status: 'start',
  })
  const [email, updateEmail] = useState('')
  const { message, status } = apiStatus

  const doSubscribe = async () => {
    const REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!email || !REGEX.test(email)) {
      updateApisStatus({
        message: 'please enter a valid email address',
        status: 'error',
      })
      return
    }

    try {
      const { success, messages } = await newsletterSignupApi({
        email,
        requestSource: REQUEST_SOURCE.EASEL_FOOTER,
        sourceURL: window.location.href,
      })

      if (success === true) {
        updateApisStatus({
          status: 'success',
          message: `Great! You've been subscribed.`,
        })

        // GTM
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'newsletter signup success',
            prompt: 'footer',
            email,
          })
        }
      } else {
        updateApisStatus({
          status: 'error',
          message: messages[0],
        })
      }
    } catch (error) {
      updateApisStatus({
        status: 'error',
        message: error,
      })
    }
  }

  const onSubmit = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    ev.preventDefault()
    doSubscribe()
  }

  const onChangeInput = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    updateEmail(ev.target.value || '')
  }

  return (
    <section data-section='email-signup'>
      <div>
        <h2>Saatchi Art Digest</h2>
        <p>Discover new art and collections added weekly by our curators.</p>
      </div>

      <form onSubmit={onSubmit}>
        <input
          required
          data-status={status}
          type='email'
          placeholder='Email Address'
          onChange={onChangeInput}
          value={email}
          role='button'
          aria-label='Email Address'
        />
        {message && (
          <p data-type='form-message' data-status={status}>
            {message}
          </p>
        )}

        <button type='submit' data-status={status}>
          <SATypography variant={SATypographyVariant.TINY}>
            {status === 'success' ? <p>x</p> : 'Sign up'}
          </SATypography>
        </button>
      </form>
    </section>
  )
}

export default EmailSignup
