import React from 'react'
import type { ConnectorProps as CartProps } from 'connectors/Cart'
import CartConnect from 'connectors/Cart'

const CartCount = ({
  cartCount,
}: CartProps): React.ReactElement<React.ComponentProps<any>, any> => {
  return <>{cartCount || 0}</>
}

export default CartConnect(CartCount)