import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const Header = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    background: #ffffff;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    border-bottom: 1px solid #e9e9e9;
    height: 54px;
  }

  [data-section='logos'] {
    display: flex;

    a[data-type='logo'] {
      position: relative;
      padding: 10px 25px;
      border-right: 1px solid #eeeeee;
      svg {
        transition: opacity 0.2s ease-in-out 0s;
        will-change: opacity;
        opacity: 0.4;
      }
      &:hover svg {
        opacity: 1;
      }
      &[active] :after {
        position: absolute;
        content: '';
        background-color: rgb(51, 51, 51);
        height: 2px;
        bottom: -1px;
        left: 0px;
        right: 0px;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }

  [data-section='icons'] {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 350px;
    min-height: 38px;
    > * {
      margin-right: 10px;
    }
    > [data-type='giftcard'] {
      margin-right: 2px;
      margin-top: 5px;
    }

    a[data-type='studio'] {
      display: flex;
      justify-conent: center;
      align-items: center;
      [data-type='studio-icon'] {
        margin: 5px 0 0;
      }
      span {
        font-weight: normal;
        font-size: 14px;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      flex-direction: row-reverse;
      min-width: 75px;
      a[data-type='studio'],
      a[data-type='giftcard'],
      a[data-type='favorites'] {
        display: none;
      }
    }
  }

  [data-section='top'] {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      height: 100%;
      order: 2;
      width: auto;
    }
  }

  [data-section='bottom'] {
    width: 100%;
    height: 76px;
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeeee;

    [data-type='toggle-search'] {
      display: none;
    }

    a[data-type='saatchi-logo'] {
      padding: 0;
      img,
      svg {
        height: 30px;
        width: auto;
        margin-top: 5px;
        @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
          height: 23px;
        }
      }
    }

    span[data-type='spacer'] {
      display: none;
    }

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      height: 100%;
      order: 1;
      justify-content: flex-start;
      padding: 0;

      span[data-type='spacer'] {
        display: flex;
        flex: 1 auto;
      }

      [data-type='toggle-search'] {
        display: flex;
        padding: 10px 13px;
        svg {
          height: 16px;
          width: 16px;
          g {
            stroke: ${(props) => props.theme.colors.secondary};
          }
        }
      }
    }
  }

  nav[data-section='nav-links'] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    [data-section='callout'] {
      button {
        display: none;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        button {
          display: block;
          position: absolute;
        }
      }
      [data-type='title-holder'] {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
          align-self: center;
          width: 100%;
        }

        a[data-type='main-link'] {
          font-size: 14px;
          font-weight: 600;
          @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
            padding: 0;
          }
        }
        a[data-type='see-all'] {
          text-decoration: underline;
          font-size: 14px;
          line-height: 16px;
          padding: 0;
          font-weight: normal;
        }
        @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
          button,
          a[data-type='see-all'] {
            display: none;
          }
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        width: 100%;
        display: none;
        align-items: center;
        padding: 10px;
        &[data-visible='true'] {
          display: flex;
          border: 1px solid #eeeeee;
          border-right: 0px;
          height: 60px;
          > * {
            height: auto;
            padding: 25px 15px;
          }
          svg {
            width: 12px;
            height: 12px;
            transform: rotate(180deg);
          }
        }
      }
    }

    > a[data-type='main-link'] {
      padding: 15px 10px;
      font-weight: 600;
      font-size: 14px;
      border-bottom: 1px solid #ffffff;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333333;
      text-decoration: none;
      cursor: pointer;

      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        display: none;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      position: fixed;
      top: ${(props) => props.offset}px;
      right: 0;
      height: 100%;
      width: 100%;
      display: block;
      overflow: hidden scroll;
      background: #ffffff;
      -webkit-font-smoothing: antialiased;
      /* to stop flickering of text in safari */
      transform-origin: 0% 0%;
      transform: translate(100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

      &[data-mobile-visible] {
        transform: translate(0, 0);
      }
    }
  }

  a {
    padding: 0 8px;
    text-decoration: none;
    color: #333333;
    transition: none 0s ease 0s;
    font-weight: bold;
    display: inline-block;
    font-size: 11px;
  }

  [data-type='heart-icon'] {
    display: block;
    width: 26px;
  }

  [data-type='cart'] {
    width: 30px;
    position: relative;
    padding: 0;
    margin-top: 3px;
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      margin: 6px 25px 0 0;
    }
    [data-type='cart-count'] {
      position: absolute;
      top: -1px;
      font-size: 12px;
      line-height: 1.15;
      left: 55%;
      transform: translateX(-50%);
      color: #333333;
      font-weight: normal;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        font-size: 10px;
        left: 53%;
      }
    }
    [data-type='cart-icon'] {
      height: 22px;
      width: 28px;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        height: 16px;
      }
    }
  }

  #toggle-menu {
    display: none;

    &[data-checked] ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;
      top: 23px;
      width: 50%;
      position: absolute;
      height: 1px;
      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
        top: 22px;
        position: absolute;
      }
      &:nth-last-child(3) {
        opacity: 0;
      }
    }
    &[data-checked] ~ [data-section='mobile-nav'] {
      transform: none;
    }
  }

  [data-type='hamburger'] {
    width: 45px;
    height: 100%;
    padding: 15px 12px;
    position: relative;
    [data-type='line'] {
      background-color: #000000;
      display: block;
      width: 100%;
      height: 2px;
      margin: 5px 0;
    }
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }

  [data-section='mobile-nav'] {
    position: fixed;
    top: ${(props) => props.offset}px;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden scroll;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    [data-type='mobile-nav-content'] {
      padding: 0 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      min-height: 700px;
      > * {
        width: 100%;
      }

      [data-style='paragraph-wp'] {
        margin: 0; // in wordpress pages needs to override margin of p
      }
    }

    a[data-type='mobile-link'],
    button {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      border: none;
      outline: none;
      text-align: left;
      font-weight: bold;
      font-size: 14px;
      color: #333333;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    [data-type='logo'] {
      margin-top: 20px;
    }

    [data-type='divider'] {
      width: 115%;
      border-top: 1px solid #eeeeee;
      margin: 10px -30px;

      ~ a {
        justify-content: flex-start;
        svg {
          width: auto;
          height: auto;
        }
      }
    }

    [data-type='gift-card'] {
      margin-left: 5px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }

  [data-mobile-open='true'] {
    transform: none;
  }

  [data-section='submenu'] {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    background: #ffffff;
    opacity: 0;
    transition: opacity 0.5s ease-in-out 0s;
    will-change: opacity;
    top: 100%;
    left: 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    pointer-events: none;
    &[data-visible] {
      opacity: 1;
      pointer-events: inherit;
    }
    [data-section='content'] {
      width: 925px;
      padding: 50px;
      display: none;
      &[data-visible] {
        display: flex;
        align-items: flex-start;
      }

      [data-style='paragraph-wp'] {
        margin: 0; // in wordpress pages needs to override margin of p
      }
    }
    [data-style='image-credit'] {
      font-size: 10px;
      margin: 5px 0;
      position: absolute;
    }
    [data-type='line'] {
      height: 100%;
      display: block;
      height: 250px;
      padding: 0 25px;
      border-right: 1px solid #eeeeee;
    }
    [data-type='sub-title'] {
      margin: 0 0 20px;
    }
    ul {
      list-style: none;
      min-width: 150px;
      margin: 0;
      li {
        a {
          font-weight: normal;
          font-size: 16px;
          padding: 0;
          margin-bottom: 5px;
        }
      }
    }
    [data-type='sub-menu-image'] {
      position: relative;
      width: 250px;
      height: 250px;
      background: #cccccc;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 250px;
        height: 250px;
      }
      p {
        position: absolute;
        top: 0;
        left: 0;
        color: #ffffff;
        font-size: 30px;
        font-weight: normal;
        line-height: 1.6;
        padding: 20%;
        text-align: center;
        text-decoration: underline;
        &[data-style='Featured Mixed Media'] {
          padding: 10% 20% 0;
        }
      }
    }
    [data-type='image-title'] {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      height: 250px;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        height: auto;
      }
    }
    /* Mobile Styling */
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      position: initial;
      display: flex;
      top: 0;
      left: 0;
      opacity: 1;
      padding: 0 20px;
      border: none;
      pointer-events: inherit;
      [data-section='content'] {
        flex-direction: column;
        padding: 20px 0;
      }
      [data-type='image-wrapper'] {
        width: 100%;
      }
      [data-type='sub-menu-image'] {
        width: 100%;
        height: 60px;
        margin: 0 0 25px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          position: initial;
          width: 100%;
        }
        p {
          font-size: 24px;
          width: 100%;
          padding: 0;
          margin: 10px 0;
          &[data-style='Featured Mixed Media'] {
            padding: 0;
          }
        }
      }
      [data-style='image-credit'] {
        margin-top: -22px;
        right: 0;
        margin-right: 20px;
      }
      [data-type='line'] {
        display: none;
      }
      [data-section='content'] {
        margin-bottom: 150px;
      }
      ul {
        padding: 0;
        width: 100%;
        h3 {
          padding: 10px 0 0;
          font-size: 14px;
        }
        li {
          padding: 0 0 15px 25px;
          a {
            width: 100%;
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
  }
`
