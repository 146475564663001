import React from 'react'
import SALink from 'components/SALink'
import type { ConnectorProps as SessionProps } from 'containers/Session'
import SessionConnect from 'containers/Session'
import StudioIcon from 'svg/studio.svg'

const Component = (
  props: SessionProps
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { isSessionReady, authenticated, userTypeId } = props

  // Must have session | authenticated | artist
  if (!isSessionReady || !authenticated || userTypeId !== 2) {
    return null
  }

  return (
    <SALink data-gtm-event='nav header' data-type='studio' href='/studio' title='View Studio'>
      <StudioIcon data-type='studio-icon' width='28' height='26' />
    </SALink>
  )
}

export default SessionConnect(Component)